import React from 'react';

// Path to the logo file on your project
import DvmfLogo from '../assets/dvmf-logo.svg';

const Logo = () => (
  <img
    src={DvmfLogo}
    alt="Deer Valley Music Festival logo"
    style={{ width: 120 }}
  />
);

export default Logo;
